<template>
    <div class="article-content">
        <el-scrollbar class="article-wrap">
            <div class="top">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                    <el-breadcrumb-item>网络推广</el-breadcrumb-item>
                    <el-breadcrumb-item>商品库</el-breadcrumb-item>
                    <el-breadcrumb-item>添加商品</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="main-content">
                <div class="content-right">
                    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                        <el-form-item label="商品封面" prop="analysis" class="subject-quill">
                            <span class="item-txt-tip">建议尺寸：200px*200px，图片大小不得超过3MB以内</span>
                            <el-upload
                                    class="cover-uploader"
                                    action="stuOp/liveUploadImg"
                                    name="img"
                                    :headers="headerParam"
                                    :show-file-list="false"
                                    :on-success="handleCoverSuccess"
                                    :before-upload="beforeCoverUpload">
                                <div class="inner-box">
                                    <div class="inner-content">
                                        <i class="el-icon-plus"></i>
                                        <span class="inner-text">选择封面</span>
                                    </div>
                                </div>
                                <div class="cover" v-if="form.goods_img">
                                    <img :src="form.goods_img">
                                    <div class="cover-bottom">重新上传</div>
                                </div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="商品名称">
                            <el-input class="s500-input" v-model="form.goods_name" maxlength="14"
                                      show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="价格形势" prop="points">
                            <div class="radio-box">
                                <div class="radio-item">
                                    <el-radio v-model="form.price_type" @change="changePrice" label="0">一口价</el-radio>
                                    <div class="item-right">
                                        <div class="right-text">价格</div>
                                        <el-input class="self-input" v-model="form.price"
                                                  :disabled="form.price_type != 0"
                                                  @change="validPrice(form.price_type)">
                                            <span slot="suffix">元</span>
                                        </el-input>
                                    </div>
                                </div>
                                <div class="radio-item">
                                    <el-radio v-model="form.price_type" @change="changePrice" label="1">价格区间</el-radio>
                                    <div class="item-right">
                                        <div class="right-text">价格</div>
                                        <el-input class="self-input" v-model="form.price_begin"
                                                  :disabled="form.price_type !=1"
                                                  @change="validPrice(form.price_type)">
                                            <span slot="suffix">元</span>
                                        </el-input>
                                        <div class="divide"></div>
                                        <el-input class="self-input" v-model="form.price_end"
                                                  :disabled="form.price_type != 1"
                                                  @change="validPrice(form.price_type)">
                                            <span slot="suffix">元</span>
                                        </el-input>
                                    </div>
                                </div>
                                <div class="radio-item">
                                    <el-radio v-model="form.price_type" @change="changePrice" label="2">显示折扣价</el-radio>
                                    <div class="item-right">
                                        <div class="right-text">原价</div>
                                        <el-input class="self-input" v-model="form.price_pre"
                                                  :disabled="form.price_type != 2"
                                                  @change="validPrice(form.price_type)">
                                            <span slot="suffix">元</span>
                                        </el-input>
                                        <div class="right-text last">现价</div>
                                        <el-input class="self-input" v-model="form.price_now"
                                                  :disabled="form.price_type != 2"
                                                  @change="validPrice(form.price_type)">
                                            <span slot="suffix">元</span>
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="商品链接" prop="goods_url">
                            <el-input class="s500-input" v-model="form.goods_url"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="page-button">
                <el-button @click="goBack">返回</el-button>
                <el-button type="primary" @click="onSubmit('form')">保存</el-button>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {stuOpSaveLiveGoods, stuOpLiveGoodsDetail} from "@/utils/apis"

    export default {
        name: "createArticle",
        data() {
            const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
            const validateSourceUri = (rule, value, callback) => {
                if (value) {
                    if (reg.test(value)) {
                        callback()
                    } else {
                        this.$message({
                            message: '商品链接填写不正确',
                            type: 'error'
                        })
                        callback(new Error('商品链接填写不正确'))
                    }
                } else {
                    callback()
                }
            }
            return {
                form: {
                    goods_img: '',
                    price_type: '0',
                    price: '',
                    price_begin: '',
                    price_end: '',
                    price_pre: '',
                    price_now: '',
                    goods_name: '',
                },
                setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300
                },
                rules: {
                    goods_url: [{validator: validateSourceUri, trigger: 'blur'}]
                },
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                editId: Number(this.$route.query.edit_id) || null,
                headerParam: {
                    Authorization: localStorage.getItem('studentToken') || ''
                },
                platform: this.$route.meta.type,
            }
        },
        created() {
            if (this.editId !== null) {
                this.getGoodsDetail()
            }
        },
        methods: {
            getGoodsDetail() {
                let param = {
                    id: this.editId
                }
                stuOpLiveGoodsDetail(param).then(res => {
                    this.form.goods_name = res.data.goods_name;
                    this.form.goods_img = res.data.goods_img;
                    this.form.goods_url = res.data.goods_url;
                    this.form.price_type = JSON.stringify(res.data.price_type);
                    switch (Number(this.form.price_type)) {
                        case 0:
                            this.form.price = res.data.price_begin
                            break;
                        case 1:
                            this.form.price_begin = res.data.price_begin
                            this.form.price_end = res.data.price_end
                            break;
                        case 2:
                            this.form.price_pre = res.data.price_begin
                            this.form.price_now = res.data.price_end
                            break;
                    }
                }).catch(err => {
                    console.error(err)
                })
            },
            backBtn() {
                this.$router.go(-1)
            },
            changeFile(event) {
                let file = event.target.files[0];
                let obj = {
                    name: file.name,
                    url: ''
                }
                this.form.cover = [];
                this.form.cover.push(obj);
            },
            handleCoverSuccess(res, file) {
                if (res.code == 200) {
                    this.form.goods_img = res.data; //无域名
                    this.$message.success('上传成功')
                } else {
                    this.$message.error('上传失败，请稍后再试~')
                }
            },
            beforeCoverUpload(file) {
                const isSize = new Promise((resolve, reject) => {
                    const width = 200;
                    const height = 200;
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    img.onload = () => {
                        const valid = img.width === width && img.height === height;
                        valid ? resolve() : reject();
                    };
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                        return file;
                    }, () => {
                        this.$message.warning('图片尺寸限制为200px x 200px');
                        return Promise.reject();
                    },
                );
                return isSize;
            },
            changePrice(val) {
                switch (Number(val)) {
                    case 0:
                        this.form.price_begin = '';
                        this.form.price_end = '';
                        this.form.price_pre = '';
                        this.form.price_now = '';
                        break;
                    case 1:
                        this.form.price = '';
                        this.form.price_pre = '';
                        this.form.price_now = '';
                        break;
                    case 2:
                        this.form.price = '';
                        this.form.price_begin = '';
                        this.form.price_end = '';
                        break;
                }
            },
            validPrice(val) {
                const priceValid = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
                switch (Number(val)) {
                    case 0:
                        if (!priceValid.test(this.form.price)) {
                            this.form.price = ''
                        }
                        break;
                    case 1:
                        if (!priceValid.test(this.form.price_begin)) {
                            this.form.price_begin = ''
                        }
                        if (!priceValid.test(this.form.price_end)) {
                            this.form.price_end = ''
                        } else {
                            if (Number(this.form.price_end) < Number(this.form.price_begin)) {
                                this.form.price_end = ''
                                this.$message.warning('必须大于前一个数')
                            }
                        }
                        break;
                    case 2:
                        if (!priceValid.test(this.form.price_pre)) {
                            this.form.price_pre = ''
                        }
                        if (!priceValid.test(this.form.price_now)) {
                            this.form.price_now = ''
                        }
                        break;
                }
            },
            goBack() {
                this.$router.go(-1)
            },
            onSubmit(formName) {
                let isErrNum = 0
                if (this.form.goods_img == '') {
                    isErrNum = 1
                }
                if (this.form.goods_name == '') {
                    isErrNum = 1
                }
                if (this.form.price_type == '') {
                    isErrNum = 1
                } else {
                    switch (Number(this.form.price_type)) {
                        case 0:
                            if (this.form.price == '') {
                                isErrNum = 1
                            }
                            break;
                        case 1:
                            if (this.form.price_begin == '') {
                                isErrNum = 1
                            }
                            if (this.form.price_end == '') {
                                isErrNum = 1
                            }
                            break;
                        case 2:
                            if (this.form.price_pre == '') {
                                isErrNum = 1
                            }
                            if (this.form.price_now == '') {
                                isErrNum = 1
                            }
                            break;
                    }
                }
                if (this.form.goods_url == '') {
                    isErrNum = 1
                }
                if (isErrNum == 0) {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let obj = {};
                            switch (Number(this.form.price_type)) {
                                case 0:
                                    obj.price_begin = this.form.price
                                    break;
                                case 1:
                                    obj.price_begin = this.form.price_begin
                                    obj.price_end = this.form.price_end
                                    break;
                                case 2:
                                    obj.price_begin = this.form.price_pre
                                    obj.price_end = this.form.price_now
                                    break;
                            }
                            obj.goods_img = this.form.goods_img;
                            obj.goods_name = this.form.goods_name;
                            obj.price_type = this.form.price_type;
                            obj.goods_url = this.form.goods_url;
                            if(this.platform == 0){
                                obj.op_id = this.opId;
                                obj.course_id = this.courseId;
                            }
                            if (this.editId !== null) {
                                obj.id = this.editId;
                            }
                            stuOpSaveLiveGoods(obj).then(res => {
                                this.$message.success(res.msg);
                                this.backBtn();
                            }).catch(err => {
                                console.error(err)
                            })
                        }
                    })
                } else {
                    this.$message.warning('每项为必填！')
                    return false
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .article-content {
        display: flex;
        flex-direction: column;
        height: calc(100% - 50px);
        padding: 0 0 40px;

        .article-wrap {
            height: 100%;
            display: flex;
            flex-direction: column;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 0 20px;
                }
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F1F5FF;
            height: 40px;
            padding-bottom: 10px;

            .genera-breadcrumb {
                display: flex;
                align-items: center;

                &:before {
                    content: unset;
                }
            }
        }

        .main-content {
            margin-top: 20px;
            display: flex;

            .content-right {
                display: flex;
                flex-direction: column;
                width: 50%;

                .s500-input {
                    width: 500px;
                }
            }
        }

        .page-button {
            margin-top: 100px;
            text-align: center;

            .el-button + .el-button {
                margin-left: 30px;
            }
        }

        .cover-uploader {
            width: 500px;

            ::v-deep.el-upload {
                display: flex;
                align-items: center;

                .inner-box {
                    width: 200px;
                    height: 200px;
                    background: #F7F7F7;
                    border-radius: 4px;

                    .inner-content {
                        width: 200px;
                        height: 200px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 24px;
                            color: #BFBFBF;
                        }

                        .inner-text {
                            margin-top: 12px;
                            font-size: 16px;
                            color: #666666;
                        }
                    }
                }

                .cover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 200px;
                    height: 200px;
                    margin-left: 15px;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .cover-bottom {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 32px;
                        background: #000000;
                        opacity: 0.5;
                        border-radius: 2px;
                        color: #ffffff;
                        font-size: 16px;
                        line-height: 36px;
                    }
                }
            }
        }

        .weixin-content {
            display: flex;
            flex-direction: column;

            span {
                margin-top: 9px;
                color: #999999;
            }
        }

        .radio-box {
            .radio-item {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;

                &:first-child {
                    margin-top: 0;
                }

                .divide {
                    width: 12px;
                    height: 2px;
                    background: #D2D2D2;
                    margin: 0 8px;
                }

                .el-radio {
                    width: 80px;
                    margin-top: 13px;

                    ::v-deep.el-radio__label {
                        font-size: 16px;
                    }
                }

                .item-right {
                    width: 500px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .self-input {
                    width: 160px;
                }

                .right-text {
                    font-size: 16px;
                    width: 32px;
                    margin: 0 4px;

                    &.last {
                        margin-left: 28px;
                    }
                }
            }
        }
    }
</style>